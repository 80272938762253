$(".comparison-table ul").on("click", "li", function () {
  var pos = $(this).index() + 2;
  $("tr").find('td:not(:eq(0))').hide();
  $('td:nth-child(' + pos + ')').css('display', 'table-cell');
  $("tr").find('th:not(:eq(0))').hide();
  $('li').removeClass('active');
  $(this).addClass('active');
});

// Initialize the media query
var mediaQuery = window.matchMedia('(max-width: 780px)');

// Add a listen event
mediaQuery.addListener(collapse);

// Function to do something with the media query

function collapse(mediaQuery) {
  if (mediaQuery.matches) {
    $('.sep').attr('colspan', 2);
  }
}

// On load
collapse(mediaQuery);