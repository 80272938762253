/** For additional info about this integration look into mailchimp-tags.html file */

var overlay = function overlay(open) {
  var body = $("body");
  var overlay = $(".modal-overlay");
  if (open && !overlay.length) {
    body.append('<div class="modal-overlay"></div>');
  } else if (!open && overlay.length) {
    overlay.remove();
  }
};
(function ($, DGS) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    $('.flexspot-mailchimp a[class^="button"]').on("click", function () {
      overlay(true);
      $("#mc_embed_signup").dialog({
        width: 700,
        dialogClass: "mailchimpDialog",
        close: function close() {
          overlay(false);
        }
      });
    });
  });
  $("body").on("click", ".modal-overlay", function () {
    $(".mailchimpDialog .ui-dialog-titlebar-close").click();
  });
})(jQuery, window.DGS);