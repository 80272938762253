require('core-js');require('regenerator-runtime/runtime');import $ from "jquery";
window.jQuery = $;
window.$ = $;
require("../../../wdh-foundation/dgs/dgs-api.js");
require("../../../wdh-foundation/dgs/dgs-events.js");
require("@demant/megamenu");
require("../../../wdh-foundation/dgs-utils/dgs-utils.js");
require("@demant/burgermenu");
require("jquery-nice-select/js/jquery.nice-select.min.js");
require("jquery-ui-dist/jquery-ui.min.js");
require("iframe-resizer");
require("./foundation/main.js");
require("./feature/cookies.js");
require("./feature/extranet.js");
require("../../corporate-template/js/feature/wdh-resource-center");
require("./feature/form.js");
require("./feature/iframe.js");
require("./feature/image-spot.js");
require("./feature/intro-banner.js");
require("./feature/rich-text.js");
require("../../corporate-template/js/feature/search-base.js");
require("../../corporate-template/js/feature/search.js");
require("./feature/text-image-spot.js");
require("./feature/two-section-grid.js");
require("./feature/video-spot.js");
require("./project/comparison");
require("./project/header.js");
require("./feature/component-accordion-spot");
require("./feature/component-flexspot-pardot-form.js");
require("@demant/wdh-gdpr/lib/default.implementation.js");

// blog
require("./feature/blog/blog.js");
require("./feature/mailchimp");